import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

//Prime React Component Inbuilt
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Toast } from 'primereact/toast';
import { BreadCrumb } from 'primereact/breadcrumb';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';

// Column
import { UsersListForSMSCampaign } from '../../../appconfig/DatatableSetting';

// Data table
import {
    defaultPageRowOptions,
    defaultRowOptions,
    paginatorLinks,
    showingEntries,
    SkeletonbodyTemplate,
    Skeletonitems,
} from '../../../appconfig/Settings';

//Services
import PageService from '../../../service/PageService';

import moment from 'moment';

import { Loader } from '../../../components/Loader/Loader';
import { addSMSCampaignValidate } from '../../../config/Validate';

export const AddUpdateSMSCampaign = () => {
    document.title = "Add Update SMS Campaign | Venture Studio"

    //BreadCrumb
    const items = [
        {
            label: 'InputText',
            template: () => <span className="p-breadcrumb-item active">Campaign</span>
        }
    ];
    const home = { icon: 'pi pi-home', url: '/dashboard' }

    //Navigate Another Route
    const navigate = useNavigate();

    const toast = useRef<any>(null);

    // Page service
    const pageService = new PageService();

    const [pageLoad, setPageLoad] = useState<boolean>(false);
    const [users, setUsers] = useState<any>([]);
    const [campaignData, setCampaignData] = useState<any>({});
    const [submitLoader, setSubmitLoader] = useState<boolean>(false);
    const [selectedUsers, setSelectedUsers] = useState<any>(null);
    const [editId, setEditId] = useState<any>(null);
    const [errors, setErrors] = useState<any>({});

    // use effect method
    useEffect(() => {
        getInvestorsForCampaignFromAPI();
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, []);

    const getInvestorsForCampaignFromAPI = () => {
        // Api call
        pageService
            .getInvestorsListForCampaign()
            .then((response) => {
                // Get response
                if (response) {
                    const DataList = response;
                    if (DataList.length == 0) {
                        setUsers([]);
                    } else {
                        setUsers(DataList);
                    }
                    setPageLoad(true);
                } else {
                    setPageLoad(false);
                    setUsers([]);
                }
            });
    };

    //On Change Campaign Data
    const onInputChange = (e: any, name: string) => {
        let val;
        val = (e.target && e.target.value) || '';
        console.log(val);
        setCampaignData({ ...campaignData, [name]: val });
    };

    // On Submit Api Call
    const onSubmitApiCall = () => {
        const { errors, isError } = addSMSCampaignValidate(campaignData, selectedUsers);
        setErrors(errors);
        if (!isError) {
            setSubmitLoader(true);

            let formData = new FormData();
            formData.append('campaign_name', campaignData?.campaign_name);
            formData.append('message_body', campaignData?.message_body);
            formData.append('recipients', JSON.stringify(selectedUsers));

            // Convert date format
            const dateString = campaignData?.start_datetime;
            const formattedDate = moment(dateString).format('MM/DD/YY hh:mm A');
            formData.append('start_datetime', formattedDate);

            // call api
            pageService.addUpdateSMSCampaign(formData).then((response) => {
                // Get response
                if (response) {
                    setSubmitLoader(false);
                    setCampaignData({});
                    setSelectedUsers(null);
                    toast.current?.show({
                        severity: 'success',
                        summary: 'Success',
                        detail: response.message,
                    });
                    setTimeout(() => {
                        navigate('/sms-campaigns');
                    }, 1000);
                } else {
                    setSubmitLoader(false);
                    toast.current?.show({
                        severity: 'error',
                        summary: 'Error',
                        detail: 'Something went wrong, Please try again.',
                    });
                }
            }).catch(error => {
                setSubmitLoader(false);
                toast.current?.show({
                    severity: 'error',
                    summary: 'Message',
                    detail: error.response.data.error,
                });
            });
        }
    };

    return (
        <>
            <Toast ref={toast} />
            <div className="page-header">
                <div className="page-leftheader">
                    <div className="page-header-info">
                        <div className="page-title"><Button icon="pi pi-arrow-left" className="link-prev-btn" onClick={() => navigate(-1)} /> {editId !== null ? "Update Campaign Details" : "Add Campaign"}</div>
                        <div className="main-content-breadcrumb">
                            <BreadCrumb model={items} home={home} />
                        </div>
                    </div>
                </div>
            </div>
            <div className="card">
                <div className="card-header">
                    <div className="card-title-box">
                        <h3 className="card-title">Enter Campaign Details</h3>
                    </div>
                </div>
                <div className="card-body">
                    <div className="p-fluid formgrid grid">
                        <div className="field col-12 md:col-6">
                            <label htmlFor="name">Campaign Name <span style={{ color: "red" }}>*</span></label>
                            <InputText
                                value={campaignData?.campaign_name}
                                name="name"
                                autoComplete="off"
                                placeholder="Enter Campaign Name"
                                onChange={(e) => onInputChange(e, "campaign_name")}
                                className={errors['campaign_name'] && 'p-invalid'}
                            />
                            <small className="p-invalid-txt">{errors['campaign_name']}</small>
                        </div>
                        
                        <div className="field col-12 md:col-6">
                            <label htmlFor="name">Select Date & Time <span style={{ color: "red" }}>*</span></label>
                            <Calendar
                                id="calendar-12h" 
                                value={campaignData?.start_datetime}
                                onChange={(e) => onInputChange(e, "start_datetime")} 
                                showIcon
                                showTime 
                                hourFormat="12"
                                minDate={new Date()}
                                className={errors['start_datetime'] && 'p-invalid'}
                            />
                            <small className="p-invalid-txt">{errors['start_datetime']}</small>
                        </div>
                        
                    </div>

                    <div className="p-fluid formgrid grid">
                        <div className="field col-12 md:col-6">
                            <label htmlFor="name">Message <span style={{ color: "red" }}>*</span></label>
                            <InputTextarea
                                value={campaignData?.message_body}
                                onChange={(e) => onInputChange(e, "message_body")}
                                rows={5}
                                cols={30}
                            />
                            <small className="p-invalid-txt">{errors['message_body']}</small>
                        </div>
                    </div>
                </div>
                <div className="card-body">
                    <h5>Select Investors To Send Message To <span style={{ color: "red" }}>*</span></h5>
                    <small className="p-invalid-txt">{errors['selected_users']}</small>
                    <div className="p-fluid formgrid grid">
                        <div className="field col-12 md:col-12">
                            {/* Datatable Start */}
                            {pageLoad == true ? (
                                <>
                                    <DataTable
                                        className="datatable-responsive" stripedRows
                                        value={users}
                                        paginator={users.length > 0 && true}
                                        rows={defaultRowOptions}
                                        rowsPerPageOptions={defaultPageRowOptions}
                                        paginatorTemplate={paginatorLinks}
                                        currentPageReportTemplate={showingEntries}
                                        selectionMode='checkbox'
                                        selection={selectedUsers}
                                        onSelectionChange={(e) => setSelectedUsers(e.value)}
                                        dataKey="id"
                                        emptyMessage="No Investors Found"
                                    >
                                        <Column
                                            selectionMode="multiple"
                                            headerStyle={{ width: '3rem' }}
                                        />
                                        {UsersListForSMSCampaign.map((col, i) => {
                                            if (col.field === 'sr_no') {
                                                return (
                                                    <Column
                                                        key={col.field}
                                                        field={col.field}
                                                        header={col.header}
                                                        body={(_, { rowIndex }) => rowIndex + 1}
                                                    />
                                                );
                                            } else {
                                                return (
                                                    <Column
                                                        key={col.field}
                                                        field={col.field}
                                                        header={col.header}
                                                        sortable
                                                        filter
                                                    />
                                                );
                                            }
                                        })}
                                    </DataTable>
                                </>
                            ) : (
                                <>
                                    {/* Skeleton Data table */}
                                    <DataTable value={Skeletonitems}>
                                        {UsersListForSMSCampaign.map((col, i) => (
                                            <Column
                                                key={col.field}
                                                field={col.field}
                                                header={col.header}
                                                body={SkeletonbodyTemplate}
                                            />
                                        ))}
                                    </DataTable>
                                </>
                            )}
                            {/* Datatable End */}
                        </div>
                    </div>
                </div>
                <div className="card-footer">
                    <div className="button-group">
                        <Button label="Save" loading={submitLoader} onClick={onSubmitApiCall} />
                    </div>
                </div>
            </div>
        </>
    )
}