import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

//Prime React Component Inbuilt
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { BreadCrumb } from 'primereact/breadcrumb';
import { Badge } from 'primereact/badge';

// Column
import { SMSCampaignsColumns } from '../../../appconfig/DatatableSetting';

// Data table
import {
    defaultPageRowOptions,
    defaultRowOptions,
    paginatorLinks,
    showingEntries,
    SkeletonbodyTemplate,
    Skeletonitems,
} from '../../../appconfig/Settings';

//Services
import PageService from '../../../service/PageService';

export const SMSCampaignList = () => {
    document.title = "SMS Campaigns | Venture Studio"

    //BreadCrumb
    const items = [
        {
            label: 'InputText',
            template: () => <span className="p-breadcrumb-item active">SMS Campaign</span>
        }
    ];
    const home = { icon: 'pi pi-home', url: '/dashboard' }

    //Navigate Another Route
    const navigate = useNavigate();

    //Set Toast/ Filter Properties
    const [globalFilter, setGlobalFilter] = useState<any>(null);

    //Loading/Page Loading
    const [pageLoad, setPageLoad] = useState(false);
    const [campaigns, setCampaigns] = useState<any>([]);

    // Page service
    const pageService = new PageService();

    // use effect method
    useEffect(() => {
        getSMSCampaignsDataFromAPI();
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, []);

    // Get SMS Camapaigns
    const getSMSCampaignsDataFromAPI = async () => {
        // Api call
        pageService
            .getSMSCampaigns()
            .then((response) => {
                // Get response
                if (response) {
                    const DataList = response;
                    if (DataList.length == 0) {
                        setCampaigns([]);
                    } else {
                        setCampaigns(DataList);
                    }
                    setPageLoad(true);
                } else {
                    setPageLoad(false);
                    setCampaigns([]);
                }
            });
    };

    // left part of toolbar
    const leftToolbarTemplate = () => {
        return (
            <>
                <span className="block mt-2 md:mt-0 p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText
                        type="search"
                        onInput={(e) => setGlobalFilter(e.currentTarget.value)}
                        placeholder="Search..."
                    />
                </span>
            </>
        );
    };

    // Template for action body
    const actionBodyTemplate = (rowData: any, rowIndex: any) => {
        return(
            <>
                <div className="flex flex-wrap gap-2">
                    <Button
                        type="button"
                        icon="pi pi-eye"
                        className='p-button-secondary p-button-square'
                        onClick={() => navigate('/sms-campaign-details', { state: { campaign_id: rowData.id } })}
                    />
                </div>
            </>
        );
    };

    // Template for status
    const statusBodyTemplate = (rowData: any, rowIndex: any) => {
        return (
            <>
                {rowData?.status == "Completed" ? <Badge value="Completed" severity="success" /> : rowData?.status == "Pending" ? <Badge value="Pending" severity="warning" /> : <Badge value="Running" severity="info" />}
            </>
        );
    };

    return(
        <>
            <div className="page-header">
                <div className="page-leftheader">
                    <div className="page-header-info">
                        <div className="page-title">SMS Campaigns</div>
                        <div className="main-content-breadcrumb">
                            <BreadCrumb model={items} home={home} />
                        </div>
                    </div>
                </div>
                <div className="page-rightheader">
                    <div className="btn-icon-list">
                        <div className="page-header-search">
                            <Toolbar className="page-header-search-area" left={leftToolbarTemplate}></Toolbar>
                        </div>
                        <Button className="p-button mr-2" label="Add New Campaign" onClick={() => navigate('/add-update-campaign')} />
                    </div>
                </div>
            </div>
            <div className="grid crud-demo">
                <div className="col-12">
                    <div className="card">
                        <div className="card-body">
                            {/* Datatable Start */}
                            {pageLoad == true ? (
                                    <>
                                        <DataTable
                                            className="datatable-responsive" stripedRows
                                            value={campaigns}
                                            paginator={campaigns.length > 0 && true}
                                            globalFilter={globalFilter}
                                            rows={defaultRowOptions}
                                            rowsPerPageOptions={defaultPageRowOptions}
                                            paginatorTemplate={paginatorLinks}
                                            currentPageReportTemplate={showingEntries}
                                            emptyMessage="No Campaigns Found"
                                        >
                                            {SMSCampaignsColumns.map((col, i) => {
                                                if (col.field === 'action') {
                                                    return (
                                                        <Column
                                                            key={col.field}
                                                            field={col.field}
                                                            header={col.header}
                                                            body={actionBodyTemplate}
                                                        />
                                                    );
                                                } else if (col.field === 'status') {
                                                    return (
                                                        <Column
                                                            key={col.field}
                                                            field={col.field}
                                                            header={col.header}
                                                            body={statusBodyTemplate}
                                                        />
                                                    );
                                                } else if (col.field === 'sr_no') {
                                                    return (
                                                        <Column
                                                            key={col.field}
                                                            field={col.field}
                                                            header={col.header}
                                                            body={(_, { rowIndex }) => rowIndex + 1}
                                                        />
                                                    );
                                                } else {
                                                    return (
                                                        <Column
                                                            key={col.field}
                                                            field={col.field}
                                                            header={col.header}
                                                            sortable
                                                        />
                                                    );
                                                }
                                            })}
                                        </DataTable>
                                    </>
                                )
                            : (
                                <>
                                    {/* Skeleton Data table */}
                                    <DataTable value={Skeletonitems}>
                                        {SMSCampaignsColumns.map((col, i) => (
                                            <Column
                                                key={col.field}
                                                field={col.field}
                                                header={col.header}
                                                body={SkeletonbodyTemplate}
                                            />
                                        ))}
                                    </DataTable>
                                </>
                            ) }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}