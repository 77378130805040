import React, { useState, useEffect, useRef } from 'react';
import { Worker, Viewer } from '@react-pdf-viewer/core';
import { pdfjs } from 'react-pdf';
import { zoomPlugin } from '@react-pdf-viewer/zoom';
import { APP_BASE_URL } from '../appconfig/Settings';
import { VIEW_INVESTMENT_MATERIAL } from '../config/ApiConstant';
import axios from 'axios';

import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import '@react-pdf-viewer/zoom/lib/styles/index.css';

const PDFViewer = (fileId: any) => {
    const [pdfData, setPdfData] = useState<any>(null);
    const [error, setError] = useState<any>('');
    const hasFetchedData = useRef(false);

    const zoomPluginInstance = zoomPlugin();
    const { ZoomInButton, ZoomOutButton } = zoomPluginInstance;

    useEffect(() => {
        if (hasFetchedData.current) return;
        hasFetchedData.current = true;

        fetchPdfFile();
        return () => {
            if (pdfData) {
                URL.revokeObjectURL(pdfData);
            }
        };
    }, [fileId]);

    // Fet pdf file from api
    const fetchPdfFile = async () => {
        try {
            const response = await axios({
                url: `${APP_BASE_URL + VIEW_INVESTMENT_MATERIAL}?file_id=${fileId.fileId}`,
                method: 'GET',
                responseType: 'blob',
                headers: {
                    Authorization: "Bearer " + localStorage.getItem('token')
                }
            });

            // Create a URL for the binary file
            const fileURL = URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
            setPdfData(fileURL);
        } catch (error) {
            setError('Failed to load PDF file.');
            console.error('Error fetching the PDF file:', error);
        }
    };

    if (error) {
        return <div>{error}</div>;
    }

    return (
        <div
            onContextMenu={(e) => e.preventDefault()}
        >
            {pdfData ? (
                <Worker workerUrl={`https://unpkg.com/pdfjs-dist@2.16.105/build/pdf.worker.min.js`}>
                    <Viewer
                        fileUrl={pdfData}
                        plugins={[zoomPluginInstance]}
                        enableSmoothScroll={true}
                    />
                </Worker>
            ) : (
                <div>Loading PDF...</div>
            )}
        </div>
    );
};

export default PDFViewer;