//interface for columns
interface ColumnMeta {
  field: string;
  header: string;
}

//Client List Columns
export const ClientsColumns: ColumnMeta[] = [
  { field: 'sr_no', header: 'Sr No.' },
  { field: 'full_name', header: 'Full Name' },
  // { field: 'email', header: 'Email' },
  { field: 'mobile', header: 'Mobile No.'},
  { field: 'company_name', header: 'Company Name'},
  { field: 'status', header: 'Status' },
  { field: 'action', header: 'Action' },
];

//Team Leader List Columns
export const TeamLeaderColumns: ColumnMeta[] = [
  { field: 'sr_no', header: 'Sr No.' },
  { field: 'full_name', header: 'Full Name' },
  // { field: 'email', header: 'Email' },
  { field: 'mobile', header: 'Mobile No.' },
  { field: 'brokers', header: 'Brokers' },
  { field: 'status', header: 'Status' },
  { field: 'action', header: 'Action' },
];

//Broker List Columns
export const BrokersColumns: ColumnMeta[] = [
  { field: 'sr_no', header: 'Sr No.' },
  { field: 'full_name', header: 'Full Name' },
  // { field: 'email', header: 'Email' },
  { field: 'mobile', header: 'Mobile No.' },
  { field: 'leadgen', header: 'Lead Team' },
  { field: 'status', header: 'Status' },
  { field: 'action', header: 'Action' },
];

//Lead Generators List Columns
export const LeadGeneatorsColumns: ColumnMeta[] = [
  { field: 'sr_no', header: 'Sr No.' },
  { field: 'full_name', header: 'Full Name' },
  // { field: 'email', header: 'Email' },
  { field: 'mobile', header: 'Mobile No.' },
  { field: 'datascrapper', header: 'Data Team' },
  { field: 'status', header: 'Status' },
  { field: 'action', header: 'Action' },
];

//Data Scrappers List Columns
export const DataScrappersColumns: ColumnMeta[] = [
  { field: 'sr_no', header: 'Sr No.' },
  { field: 'full_name', header: 'Full Name' },
  // { field: 'email', header: 'Email' },
  { field: 'mobile', header: 'Mobile No.' },
  { field: 'status', header: 'Status' },
  { field: 'action', header: 'Action' },
];

//Investors List Columns
export const InvestorsColumns: ColumnMeta[] = [
  { field: 'sr_no', header: 'Sr No.' },
  { field: 'full_name', header: 'Full Name' },
  // { field: 'email', header: 'Email' },
  { field: 'mobile', header: 'Mobile No.' },
  { field: 'status', header: 'Status' },
  { field: 'action', header: 'Action' },
];

//Job Roles Columns
export const JobRolesColumns: ColumnMeta[] = [
  { field: 'sr_no', header: 'Sr No.' },
  { field: 'name', header: 'Role' },
  { field: 'status', header: 'Status' },
  { field: 'action', header: 'Action' },
];

//Job Companies Columns
export const JobCompaniesColumns: ColumnMeta[] = [
  { field: 'sr_no', header: 'Sr No.' },
  { field: 'photo', header: 'Logo' },
  { field: 'name', header: 'Company Name' },
  { field: 'status', header: 'Status' },
  { field: 'action', header: 'Action' },
];

// Careers Columns
export const CareersColumns: ColumnMeta[] = [
  { field: 'sr_no', header: 'Sr No.' },
  { field: 'job_company', header: 'Company' },
  { field: 'job_title', header: 'Job Title' },
  { field: 'job_role', header: 'Role' },
  { field: 'salary', header: 'Salary'},
  { field: 'experience', header: 'Experience' },
  { field: 'created_at', header: 'Date' },
  { field: 'status', header: 'Status' },
  { field: 'action', header: 'Action' },
];

// Applicants Columns
export const ApplicantsColumns: ColumnMeta[] = [
  { field: 'sr_no', header: 'Sr No.' },
  { field: 'full_name', header: 'Name' },
  // { field: 'email', header: 'Email' },
  { field: 'mobile', header: 'Mobile No.' },
  { field: 'company_name', header: 'Company' },
  { field: 'job_title', header: 'Job Title' },
  { field: 'job_role', header: 'Role' },
  { field: 'created_at', header: 'Date' },
  { field: 'task_file', header: 'Task' },
  { field: 'status', header: 'Status' },
  { field: 'action', header: 'Action' },
];

//Investment Material Columns
export const InvestmentMaterialColumns: ColumnMeta[] = [
  { field: 'sr_no', header: 'Sr No.' },
  { field: 'file_name', header: 'Name' },
  { field: 'is_confidential', header: 'confidentiality' },
  { field: 'is_flipbook', header: 'Flip Book' },
  { field: 'action', header: 'Action' },
];

//Investment Material Approval Columns
export const InvestmentMaterialApprovalColumns: ColumnMeta[] = [
  { field: 'sr_no', header: 'Sr No.' },
  { field: 'broker_name', header: 'Broker' },
  { field: 'investor_name', header: 'Investor' },
  { field: 'material_name', header: 'File Name' },
  { field: 'status', header: 'Status' },
  { field: 'action', header: 'Action' },
];

//Investment MaterialSelection Columns
export const InvestmentMaterialSelectionBrokerColumns: ColumnMeta[] = [
  { field: 'file_name', header: 'Name' },
  { field: 'is_confidential', header: 'confidentiality' },
  { field: 'action', header: '' },
];

//Investment MaterialSelection Columns
export const InvestmentMaterialSelectionAdminColumns: ColumnMeta[] = [
  { field: 'file_name', header: 'Name' },
  { field: 'is_confidential', header: 'confidentiality' },
  { field: 'action', header: '' },
];

//Login Analysis Dashboard Columns
export const LoginAnalysisDashboardColumns: ColumnMeta[] = [
  { field: 'full_name', header: 'Name' },
  { field: 'login_count', header: 'Logged In' }
];

//Login Analysis Columns
export const LoginAnalysisColumns: ColumnMeta[] = [
  { field: 'created_at', header: 'Logged In At' },
];

//Investment Material Open Time Columns
export const InvtMaterialOpenTimeBrokerColumns: ColumnMeta[] = [
  { field: 'material_name', header: 'Name' },
  { field: 'total_read_time', header: 'Read Time' },
  { field: 'material_open_count', header: 'Opened' },
];

export const InvtMaterialOpenTimeAdminColumns: ColumnMeta[] = [
  { field: 'file_name', header: 'Name' },
  { field: 'total_read_time', header: 'Read Time' },
  { field: 'material_open_count', header: 'Opened' },
];

//Investor Investment Material
export const InvestorInvestmentMaterialColumns: ColumnMeta[] = [
  { field: 'file_name', header: 'File' },
  { field: 'action', header: '' },
];

//Investment Material Folder
export const InvestmentMaterialFolderColumns: ColumnMeta[] = [
  { field: 'folder_name', header: 'Name' },
  { field: 'total_files', header: 'Files' },
];

// Trash Folder Columns
export const TrashFolderColumns: ColumnMeta[] = [
  { field: 'folder_name', header: 'Name' },
  { field: 'action', header: '' },
];

// Trash File Columns
export const TrashFileColumns: ColumnMeta[] = [
  { field: 'file_name', header: 'Name' },
  { field: 'action', header: '' },
];

export const LeadsListColumns: ColumnMeta[] = [
  { field: 'sr_no', header: 'Sr No.' },
  { field: 'full_name', header: 'Name' },
  { field: 'Email', header: 'Email' },
  { field: 'CE_Engagement_Status', header: 'CE Engagement Status' },
  { field: 'Phone', header: 'Mobile No.'},
  { field: 'action', header: 'Action' },
];

export const TasksColumns: ColumnMeta[] = [
  { field: 'task_detail', header: 'Task' },
  { field: 'created_at', header: 'Date' },
  { field: 'status', header: 'Status' },
];

export const CampaignsColumns: ColumnMeta[] = [
  { field: 'sr_no', header: 'Sr No.' },
  { field: 'name', header: 'Campaign' },
];

export const SMSCampaignsColumns: ColumnMeta[] = [
  { field: 'sr_no', header: 'Sr No.' },
  { field: 'campaign_name', header: 'Campaign' },
  { field: 'recipients', header: 'Recipients' },
  { field: 'status', header: 'Status' },
  { field: 'added_by', header: 'Created By' },
  { field: 'start_datetime', header: 'Start Datetime' },
  { field: 'action', header: '' },
];

export const UsersListForSMSCampaign: ColumnMeta[] = [
  { field: 'sr_no', header: 'Sr No.' },
  { field: 'full_name', header: 'Name' },
  { field: 'phone_number', header: 'Mobile No.' },
];

export const SMSCampaignRecipients: ColumnMeta[] = [
  { field: 'sr_no', header: 'Sr No.' },
  { field: 'full_name', header: 'Name' },
  { field: 'phone_number', header: 'Mobile No.' },
  { field: 'status', header: 'Status' },
];

export const RolesColumns: ColumnMeta[] = [
  { field: 'sr_no', header: 'Sr No.' },
  { field: 'role', header: 'Role' },
  { field: 'default_role_permissions', header: 'Menu Access' },
  { field: 'action', header: '' },
];